<template>
    <div class="wrapper" id="wrapper-content">
        <div class="wrapper-in">
            <AppHeader></AppHeader>
            <AppSidebar></AppSidebar>
            <main class="content" id="content-layout">
                <home></home>
                <transition name="fade" mode="out-in">
                    <div class="body-overlay" id="body-overlay" @click="closeMobileMenu()" v-if="isOpenModalPromo || openSendwich"></div>
                </transition>
            </main>
            <AppFooter></AppFooter>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import AppHeader from '@/components/header/header.vue'
import AppSidebar from '@/components/nav-main/mobile-sidebar.vue'

import AppFooter from '@/components/footer/footer.vue'
import home from '@/views/home.vue'
export default {
    name: 'app',
    components: {
        home,
        AppHeader,
        AppSidebar,
        AppFooter,
    },
    data () {
        return {
            iSmobile: false,
        }
    },
    computed: mapGetters({
        isOpenModalPromo: 'loadOpenModalPromo',
        openSendwich: 'loadOpenSendwich',
    }),
    methods: {
        closeMobileMenu() {
            this.$store.commit('Open_Sendwich', false);
            document.getElementsByTagName('body')[0].classList.remove('overlay-open');
        },
        // // MOBILE CHECKER
        // isMobileCheck() {
        //     if (window.innerWidth < 767) {
        //         this.iSmobile = true;
        //     }
        // },
        // handleResize() {
        //     if (window.innerWidth < 767) {
        //         this.iSmobile = true
        //     } else {
        //         this.iSmobile = false
        //     }
        // },
        // checkResize() {
        //     window.addEventListener('resize', this.handleResize)
        //     this.handleResize();
        // },
        // setNonScriptToBody() {
        //     this.yandexNoScript = '88746674';
        // },
    },
    beforeCreate() {
        if (localStorage.getItem('languageOrangeGroupSite') === null) {
            localStorage.setItem('languageOrangeGroupSite', 'ru');
        }
    },
    created () {
        // this.setNonScriptToBody();
    },
    mounted () {
        // this.isMobileCheck();
        // this.checkResize();
    }
}
</script>