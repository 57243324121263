import { f } from 'vue3-observe-visibility';
import { createStore } from 'vuex'

export default createStore({
    state: {
        isOpenFormModal: false,
        isOpenModalPromo: false,
        isOpenSendwich: false,
        mainLang: [],
        ru_lang: {
            // Основные настройки
            developer: 'Дизайн и разработка - ',
            copy: '© 2025, Orange Group. Все права защищены.',
            header: {
                textMsg: 'Здравствуйте пишу вам с сайта https://orange-group.kz',
                phoneMsg: '+77777086617',
                wapp_text: 'Написать нам в WhatsApp',
            },
            nav: [
                {
                    name: 'Наши компании',
                    href:'#about',
                },
                {
                    name: 'Дистрибьюция',
                    href:'#distribution',
                },
                {
                    name: 'Стратегия бизнеса',
                    href:'#strategy',
                },
                {
                    name: 'История компании',
                    href:'#history',
                },
                {
                    name: 'Сотрудничетсво',
                    href:'#cooperation',
                },
                {
                    name: 'Контакты',
                    href:'#contacts',
                },
            ],
            formSetup: {
                name: 'Ваше Имя',
                company: 'Компания',
                phone: '+7 (',
                email: 'Ваш E-mail',
                message: 'Ваше сообщение',
                btn: 'ОТПРАВИТЬ',
                anounce: 'Все поля обязательны для заполнения',
                sucsess_msg: 'Спасибо ваше сообщение отправлено',
                admin_email: 'smart.ind@bk.ru',
                form_title: 'У вас есть вопросы? Будем рады ответить на них',
            },
            // Главный блок
            indexPromo: {
                promo: [
                    {
                        title: 'ПРЯМАЯ ДИСТРИБЬЮЦИЯ И ПРОИЗВОДСТВО',
                        img: require('@/assets/images/samples/slide-pic-01.png'),
                    },
                    {
                        title: 'Собственный логистический комплекс и траки',
                        img: require('@/assets/images/samples/slide-pic-01.png'),
                    },
                    {
                        title: 'Бесперебойные поставки',
                        img: require('@/assets/images/samples/slide-pic-01.png'),
                    },
                    {
                        title: 'Выгодное сотрудничество для развития вашего бизнеса',
                        img: require('@/assets/images/samples/slide-pic-01.png'),
                    },
                ],
                promo_desc: {
                    number_desc: 'Открузок товаров сделанных нами за всё время',
                    full_desc: 'Сегодня наше направление деятельности – прямая дистрибьюция и производство, в совокупности с логистикой продуктов питания в розницу через собственные торговые каналы.'
                }
            },
            // Раздел Наши компании
            aboutSection: {
                btn_more: 'Подробнее',
                pageTitle: {
                    black: 'НАШИ',
                    color: 'КОМПАНИИ',
                },
                leftDesc: [
                    '«Global Foods» уже более 15 лет является официальным и единственным дистрибьютором поппулярных семечек «Джинн» и премиум снека - арахиса «Караван орехов» российской компании «Smart» на территориии Республики Казахстан.'
                ],
                rightDesc: [
                    '«TUKANO» производит лапшу быстрого приготовления ТМ «YuSheFu», снеки «Papago», «Ями-Ями» и «Twister».',
                    'Является дистрибьютором газированных напитков ТМ «Chupa Chups», муки ТМ «Беляевская».'
                ],
                aboutCards: [
                    {
                        id: 1,
                        modal: true,
                        card_img: require('@/assets/images/samples/about-cards/about-card-01.png'),
                        title: 'Компания «Global Foods» специализируется на дистрибьюции семечек «Джинн» и арахиса «Караван орехов».',
                        desc: [
                            'Дистрибьюторская продукция представлена двумя брендами российской компании «Smart» - семечки Джинн» и арахисом «Караван орехов». Семечки «Джинн» это давно полюбившийся, ароматный и яркий оригинальный вкус семечек. «Караван орехов» это отборные ядра арахиса с солью, в которых умеренное количество соли и масла превращает продукт в полноценный перекус или закуску к напиткам.'
                        ],
                        images: [
                            require('@/assets/images/samples/about-cards/about-card-01-01.png'),
                            require('@/assets/images/samples/about-cards/about-card-01-02.png'),
                            require('@/assets/images/samples/about-cards/about-card-01-03.png'),
                            require('@/assets/images/samples/about-cards/about-card-01-04.png')
                        ]
                    },
                    {
                        id: 2,
                        modal: true,
                        card_img: require('@/assets/images/samples/about-cards/about-card-02.png'),
                        title: 'Компания «Global Foods» специализируется на дистрибьюции семечек «Джинн» и арахиса «Караван орехов».',
                        desc: [
                            'Дистрибьюторская продукция представлена двумя брендами российской компании «Smart» - семечки Джинн» и арахисом «Караван орехов». Семечки «Джинн» это давно полюбившийся, ароматный и яркий оригинальный вкус семечек. «Караван орехов» это отборные ядра арахиса с солью, в которых умеренное количество соли и масла превращает продукт в полноценный перекус или закуску к напиткам.'
                        ],
                        images: [
                            require('@/assets/images/samples/about-cards/about-card-01-01.png'),
                            require('@/assets/images/samples/about-cards/about-card-01-02.png'),
                            require('@/assets/images/samples/about-cards/about-card-01-03.png'),
                            require('@/assets/images/samples/about-cards/about-card-01-04.png')
                        ]
                    },
                    {
                        id: 3,
                        modal: true,
                        card_img: require('@/assets/images/samples/about-cards/about-card-03_2.png'),
                        title: 'Chupa Chups газированный безалкогольный напиток со вкусами любимых леденцов Chupa Chups.',
                        desc: [
                            'Он чудесно освежающий и фруктово-кислый, как мы привыкли видеть в леденцах. Наслаждайтесь великолепным вкусом вашего любимого леденца в газировке!'
                        ],
                        images: [
                            require('@/assets/images/samples/about-cards/products/Chupa_1.png'),
                            require('@/assets/images/samples/about-cards/products/Chupa_2.png'),
                            require('@/assets/images/samples/about-cards/products/Chupa_3.png'),
                            require('@/assets/images/samples/about-cards/products/Chupa_4.png'),
                            require('@/assets/images/samples/about-cards/products/Chupa_5.png'),
                        ]
                    },
                    {
                        id: 4,
                        modal: true,
                        card_img: require('@/assets/images/samples/about-cards/about-card-04_2.png'),
                        title: 'Мука ТМ «Беляевская» выпускается современным мукомольным предприятием на высоко технологичном оборудовании уже более 10 лет.',
                        desc: [
                            'Полная гарантия соответствия поставляемой продукции стандартам безопасности и качества!'
                        ],
                        images: [
                            require('@/assets/images/samples/about-cards/products/Muka_01.png'),
                            require('@/assets/images/samples/about-cards/products/Muka_02.png'),
                            require('@/assets/images/samples/about-cards/products/Muka_03.png'),
                        ]
                    },
                    // {
                    //     id: 3,
                    //     modal: true,
                    //     card_img: require('@/assets/images/samples/about-cards/about-card-03.png'),
                    //     title: 'Компания «Tukano» занимается продажей собственной торговой марки бытовой химии «Уютный дом».',
                    //     desc: [
                    //         'Бытовая химия торговой марки «Уютный дом» это качественные средства для уборки дома, по выгодной цене и самым широким ассортиментом.',
                    //         'Наши средства бытовой химии создают чистоту и свежесть в вашем доме каждый день.'
                    //     ],
                    //     images: [
                    //         require('@/assets/images/samples/about-cards/products/product_07_01.png'),
                    //         require('@/assets/images/samples/about-cards/products/product_07_02.png'),
                    //         require('@/assets/images/samples/about-cards/products/product_07_03.png'),
                    //         require('@/assets/images/samples/about-cards/products/product_07_04.png'),
                    //         require('@/assets/images/samples/about-cards/products/product_07_05.png'),
                    //     ]
                    // },
                    // {
                    //     id: 4,
                    //     modal: true,
                    //     card_img: require('@/assets/images/samples/about-cards/about-card-04.png'),
                    //     title: 'Компания «Tukano» занимается дистрибьюцией продуктов питания и бытовой химиии казахстанского производителя «Всё в дом».',
                    //     desc: [
                    //         'Бытовая химия, бумажная продукция, консервы, крупы, приправы и многое-многое другое. Оптимальное соотношение цены и качества - забирайте «Всё в дом».',
                    //         'Бренд принадлежит крупному казахстанскому ретейлеру «Magnum». Выпускает Magnum товары собственной торговой марки на контрактных производствах, большая часть которых находится в Казахстане. Но, к примеру, шампиньоны консервируются в КНР, а картофельная соломка производится в Кыргызстане.'
                    //     ],
                    //     images: [
                    //         require('@/assets/images/samples/about-cards/products/product_03_01.png'),
                    //         require('@/assets/images/samples/about-cards/products/product_03_02.png'),
                    //         require('@/assets/images/samples/about-cards/products/product_03_03.png'),
                    //         require('@/assets/images/samples/about-cards/products/product_03_04.png'),
                    //         require('@/assets/images/samples/about-cards/products/product_03_05.png'),
                    //         require('@/assets/images/samples/about-cards/products/product_03_06.png'),
                    //         require('@/assets/images/samples/about-cards/products/product_03_07.png'),
                    //         require('@/assets/images/samples/about-cards/products/product_03_08.png'),
                    //         require('@/assets/images/samples/about-cards/products/product_03_09.png'),
                    //         require('@/assets/images/samples/about-cards/products/product_03_10.png'),
                    //         require('@/assets/images/samples/about-cards/products/product_03_11.png'),
                    //         require('@/assets/images/samples/about-cards/products/product_03_12.png'),
                    //     ]
                    // },
                    // {
                    //     id: 5,
                    //     modal: true,
                    //     card_img: require('@/assets/images/samples/about-cards/about-card-05.png'),
                    //     title: 'Компания «Tukano» занимается продажей собственной торговой марки макаронных изделий «Grazio».',
                    //     desc: [
                    //         'Макаронные изделия торговой марки «Grazio» натуральный продукт, приготовленный из твёрдых сортов пшеницы. Благодаря этому при варке макаронные изделия прекрасно сохраняют форму и вкусовые свойства. Широкий ассортимент изделий позволяет нашим потребителям радовать себя и свои семьи вкусными, полезными и разнообразными блюдами каждый день. Благодаря особому помолу в макаронных изделиях «Grazio» сохраняются все диетологические свойства пшеницы твёрдых сортов.'
                    //     ],
                    //     images: [
                    //         require('@/assets/images/samples/about-cards/products/product_05_01.png'),
                    //         require('@/assets/images/samples/about-cards/products/product_05_02.png'),
                    //         require('@/assets/images/samples/about-cards/products/product_05_03.png'),
                    //     ]
                    // },
                    // {
                    //     id: 6,
                    //     modal: true,
                    //     card_img: require('@/assets/images/samples/about-cards/about-card-06.png'),
                    //     title: 'Компания «Tukano» занимается дистрибьюцией бытовой химии белорусской фабрики «Сонца».',
                    //     desc: [
                    //         'Парфюмерно–косметическая компания «Сонца» - производитель современных качественных средств для стирки, продуктов бытовой химии и личной гигиены. Фабрика находится в Беларуси. В компании и на фабрике работает более 200 человек.',
                    //         'Инновационный подход к технологиям и разработке продуктов делает дистрибьюцию данной продукции востребованной.'
                    //     ],
                    //     images: [
                    //         require('@/assets/images/samples/about-cards/products/product_04_01.png'),
                    //         require('@/assets/images/samples/about-cards/products/product_04_02.png'),
                    //         require('@/assets/images/samples/about-cards/products/product_04_03.png'),
                    //         require('@/assets/images/samples/about-cards/products/product_04_04.png'),
                    //         require('@/assets/images/samples/about-cards/products/product_04_05.png'),
                    //     ]
                    // },
                    // {
                    //     id: 7,
                    //     modal: true,
                    //     card_img: require('@/assets/images/samples/about-cards/about-card-07.png'),
                    //     title: 'Компания «Tukano» занимается дистрибьюцией круп и хлопьев казахстанского производителя «Salus».',
                    //     desc: [
                    //         'Каши - основа нашего рациона. Значение блюд из круп трудно переоценить, ещё с древних времен каша была не просто пищей, но имела особый смысл, объединяя собой все слои общества. Крупы это кладезь полезных веществ: витаминов, микроэлементов, белков и клетчатки. Поэтому наша компания активно развивает дистрибьюцию в этом перспективном и полезном направлении, предлагая широкий ассортимент круп и хлопьев торговой марки «Salus».',
                    //     ],
                    //     images: [
                    //         require('@/assets/images/samples/about-cards/products/product_02_01.png'),
                    //         require('@/assets/images/samples/about-cards/products/product_02_02.png'),
                    //         require('@/assets/images/samples/about-cards/products/product_02_03.png'),
                    //         require('@/assets/images/samples/about-cards/products/product_02_04.png'),
                    //         require('@/assets/images/samples/about-cards/products/product_02_05.png'),
                    //         require('@/assets/images/samples/about-cards/products/product_02_06.png'),
                    //         require('@/assets/images/samples/about-cards/products/product_02_07.png'),
                    //         require('@/assets/images/samples/about-cards/products/product_02_08.png'),
                    //     ]
                    // },
                    {
                        id: 8,
                        modal: true,
                        card_img: require('@/assets/images/samples/about-cards/about-card-08.png'),
                        title: 'Компания «Tukano» занимается продажей собственной торговой марки лапши быстрого приготовления «YouSheFu».',
                        desc: [
                            'Лапша быстрого приготовления торговой марки «YouSheFu» - это, в первую очередь сытная лапша, с насыщенным азиатским вкусом, которая отлично утолит чувство голода.',
                            'Наша лапша быстрого приготовления прекрасно подходит для полноценного обеда или ужина на работе, дома или в походе с друзьями.'
                        ],
                        images: [
                            require('@/assets/images/samples/about-cards/products/YUSHEFU_01.png'),
                            require('@/assets/images/samples/about-cards/products/YUSHEFU_02.png'),
                            require('@/assets/images/samples/about-cards/products/YUSHEFU_03.png'),
                            require('@/assets/images/samples/about-cards/products/YUSHEFU_04.png'),
                            require('@/assets/images/samples/about-cards/products/YUSHEFU_05.png'),
                            require('@/assets/images/samples/about-cards/products/YUSHEFU_06.png'),
                        ]
                    },
                    {
                        id: 9,
                        modal: true,
                        card_img: require('@/assets/images/samples/about-cards/about-card-09.png'),
                        title: 'Компания «Tukano» занимается производством и продажей собственной торговой марки кукурузных палочек «Papago» и «Ями-Ями» и кукурузных снеков «Twister».',
                        desc: [
                            'Сладкие и воздушные, хрустящие кукурузные палочки, изготовлены из высококачественной кукурузы в нежной сахарной пудре. Продукт не содержит консервантов и генетически модифицированных компонентов. Кукурузные снеки «Twister» – миниатюрные закуски на основе кукурузы. Кукурузные палочки и снеки – любимое лакомство детей и взрослых, а приятная цена делает их доступными.'
                        ],
                        images: [
                            require('@/assets/images/samples/about-cards/products/product_08_01.png'),
                            require('@/assets/images/samples/about-cards/products/product_08_02.png'),
                            require('@/assets/images/samples/about-cards/products/product_08_03.png'),
                            require('@/assets/images/samples/about-cards/products/product_08_04.png'),
                            require('@/assets/images/samples/about-cards/products/product_08_05.png'),
                            require('@/assets/images/samples/about-cards/products/product_08_06.png'),
                        ]
                    },
                    {
                        id: 10,
                        modal: true,
                        card_img: require('@/assets/images/samples/about-cards/about-card-10.png'),
                        title: 'Компания «Tukano» занимается производством и продажей собственной торговой марки кукурузных палочек «Papago» и «Ями-Ями» и кукурузных снеков «Twister».',
                        desc: [
                            'Сладкие и воздушные, хрустящие кукурузные палочки, изготовлены из высококачественной кукурузы в нежной сахарной пудре. Продукт не содержит консервантов и генетически модифицированных компонентов. Кукурузные снеки «Twister» – миниатюрные закуски на основе кукурузы. Кукурузные палочки и снеки – любимое лакомство детей и взрослых, а приятная цена делает их доступными.'
                        ],
                        images: [
                            require('@/assets/images/samples/about-cards/products/product_08_01.png'),
                            require('@/assets/images/samples/about-cards/products/product_08_02.png'),
                            require('@/assets/images/samples/about-cards/products/product_08_03.png'),
                            require('@/assets/images/samples/about-cards/products/product_08_04.png'),
                            require('@/assets/images/samples/about-cards/products/product_08_05.png'),
                            require('@/assets/images/samples/about-cards/products/product_08_06.png'),
                        ]
                    },
                    {
                        id: 11,
                        modal: true,
                        card_img: require('@/assets/images/samples/about-cards/about-card-11.png'),
                        title: 'Компания «Tukano» занимается производством и продажей собственной торговой марки кукурузных палочек «Papago» и «Ями-Ями» и кукурузных снеков «Twister».',
                        desc: [
                            'Сладкие и воздушные, хрустящие кукурузные палочки, изготовлены из высококачественной кукурузы в нежной сахарной пудре. Продукт не содержит консервантов и генетически модифицированных компонентов. Кукурузные снеки «Twister» – миниатюрные закуски на основе кукурузы. Кукурузные палочки и снеки – любимое лакомство детей и взрослых, а приятная цена делает их доступными.'
                        ],
                        images: [
                            require('@/assets/images/samples/about-cards/products/product_08_01.png'),
                            require('@/assets/images/samples/about-cards/products/product_08_02.png'),
                            require('@/assets/images/samples/about-cards/products/product_08_03.png'),
                            require('@/assets/images/samples/about-cards/products/product_08_04.png'),
                            require('@/assets/images/samples/about-cards/products/product_08_05.png'),
                            require('@/assets/images/samples/about-cards/products/product_08_06.png'),
                        ]
                    },
                ]
            },
            // Раздел Дистрибьюция
            distributionSection: {
                pageTitle: {
                    black: 'НАША',
                    color: ' ДИСТРИБЬЮЦИЯ',
                },
                desc: 'На сегодняшний день группа компаний «Orange Group» имеет большую партнёрскую сеть в Республике Казахстан и за её пределами.',
                desc_number: 'Постоянных клиентов в базе компании на сегодняшний день',
                desc_table_up: 'Группа компаний «Orange Group» ведет свою оперативную деятельность и имеет полноценные филиалы, на территории государств, республик и городов представленных в таблице.',
                desc_table_down: 'Основные партнеры, представляющие собственную и дистрибьюторскую продукцию группы компаний «Orange Group» на территории Республики Казахстан:',
                cards_desc_left: 'Компания располагает современными автоматизированными системами продаж и ведёт онлайн передвижение торговых представителей и его онлайн продажи. Ежедневно в базу попадают данные о мерчендайзинге товара в торговой точки, выкладка и контроль рекомендуемого заказа.',
                cards_desc_right: 'Торговая команда формирует заказ клиента, активно предлагает продукт, сообщая о новинках, промо-акциях по продуктам. Торговые представители посещают каждую торговую точку 1-2 раза в неделю, отслеживая остатки на складе покупателя.',
            },
            // Раздел Стратегия
            strategySection: {
                pageTitle: {
                    black: 'СТРАТЕГИЯ',
                    color: ' БИЗНЕСА',
                },
                desc: 'В аренде компании находится современный логистический центр, собственный автопарк грузовой техники для осуществления своевременных и качественных поставок.',
                desc_number: 'квалифицированных специалистов трудится на благо компании',
                desc_left: [
                    'Переход к дистрибьюции (официальному представительству производителей) и производству собственной продукции позволяет заниматься качественным распространением продукции в регионах.',
                    'В свою очередь партнерские отношения на основе предоставления эксклюзивного права на распространение продукции позволяют дистрибьютору снижать ценовые колебания рынка, а производителю представлять интересы собственных торговых марок и брендов.'
                ],
                desc_right: [
                    '«Orange Group» использует склады класса А, соответствующие международным стандартам. Торговые команды компании оснащены современными КПК позволяющими отправлять заявки в online режиме.',
                    '«Orange Group» очень дорожит репутацией честного партнера, поэтому мы ставим порядочность, строгое соблюдение ценовой политики, соблюдение условий договора во главу работы с любым поставщиком и покупателем.'
                ]
            },
            // Раздел сотрудничество
            cooperationSection: {
                year: 'ЛЕТ',
                pageTitle: {
                    black: 'СОТРУДНИЧЕСТВО',
                    color: ' И ЭФФЕКТИВНОСТЬ',
                },
                desc: 'Сегодня «Orange Group» это быстро развивающаяся и уже сформировавшая свою чёткую позицию на рынке группа компаний.',
                desc_number: 'мы успешно ведём наш бизнес на рынке Казахстана и СНГ',
                desc_cooperation_top: 'Компания станет успешной только тогда, когда сумеет сделать успешными своих партнеров, поэтому в партнерской связке: ',
                desc_cooperation_bottom: 'задача дистрибьютора, то есть нашей компании – сделать так,  чтобы все товары были востребован потребителем.',
                cooperation: [
                    'Производитель',
                    'Дистрибьютор',
                    'Магазин',
                    'Покупатель'
                ],
                cooperation_title_left: '«Orange Group» были выработаны основные принципы эффективной дистрибьюции, которые включают в себя следующее:',
                cooperation_title_right: 'В свою очередь производитель на правах эксклюзивной дистрибуции получает следующие преимущества от партнерства с группой компаний «Orange Group»:',
                icons_list: [
                    {
                        icon: require('@/assets/images/samples/cooperation/cooperation_icon_01.svg'),
                        title: 'Продвижение товаров производителя в каждом сегменте',
                    },
                    {
                        icon: require('@/assets/images/samples/cooperation/cooperation_icon_02.svg'),
                        title: 'Команда лучших торговых представителей',
                    },
                    {
                        icon: require('@/assets/images/samples/cooperation/cooperation_icon_03.svg'),
                        title: 'Эффективное покрытие города, области и важных каналов сбыта',
                    },
                    {
                        icon: require('@/assets/images/samples/cooperation/cooperation_icon_04.svg'),
                        title: 'Прямая дистрибьюция и целесообразные каналы сбыта',
                    },
                ],
                cooperation_list: [
                    'Значительная экономия ресурсов и времени на создании собственного представительства в регионе',
                    'Логистические услуги, которые включают хранение и доставку товаров клиентам в течении 24 часов после заказа;',
                    'Максимально качественный подход к каждой товарной позиции Бренда;',
                    'Постановка целей на объемы продаж и их выполнение, а также анализ конъюнктуры рынка;',
                    '100% покрытие конкретного сегмента для конкретной товарной позиции;',
                    'Эффективная информационная система по управлению процессом доставки.'
                ],
                cooperation_orange: [
                    {
                        title: 'ПРОИЗВОДИТЕЛЬ',
                        img: require('@/assets/images/samples/cooperation/orange_icon_01.svg'),
                    },
                    {
                        title: 'ДИСТРИБЬЮТОР',
                        img: require('@/assets/images/samples/cooperation/orange_icon_02.svg'),
                    },
                    {
                        title: 'ПОКУПАТЕЛЬ',
                        img: require('@/assets/images/samples/cooperation/orange_icon_03.svg'),
                    },
                    {
                        title: 'РОЗНИЧНЫЕ МАГАЗИНЫ',
                        img: require('@/assets/images/samples/cooperation/orange_icon_04.svg'),
                    },
                ],
                cooperation_collapse: [
                    {
                        title: 'Достоинство партнерских программ и их взаимовыгодность',
                        check_items: [
                            'Мы хотели бы поставить акцент на основных преимуществах при работе с нашей компанией, как с дистрибьютором в г. Алматы, в г. Талды-Корган и Жамбыльской области.',
                            'Мы прекрасно ориентируемся в ситуации на Казахстанском рынке, а это значит, что можем держать постоянно в курсе производителя: о конкурентах, о состоянии рынка, о покупателях и т.д.',
                            'Исключение оптовых посредников в канале распределения позволяет нам получать максимально качественную информацию о сбыте по розничным торговым точкам и предоставлять данную информацию производителю.',
                            'Большая часть наших клиентов предпочитает работать с нами из-за широкого ассортимента продукции, который мы предоставляем на данный момент (помимо дистрибьюторских позиций в наличии так же есть оптовые).',
                            'Мы отлично знаем рекламный рынок региона и знаем наиболее эффективные пути воздействия рекламы на потребителя.',
                            'Эффективная система мотивирования торговой команды дает возможность осуществлять наиболее напористое предложение по каждой дистрибьюторской позиции.',
                        ],
                        check_anounce: false,
                    },
                    {
                        title: 'Кроме того при работе через внешнего дистрибьютора Поставщик получает большое финансово-экономическое  преимущество',
                        check_items: [
                            'Освобождение финансовых средств, которые могли бы быть затрачены для построения собственного представительства.',
                            'Возможность получить большую долю прибыли, норматив которой в производстве, как правило, в 2 раза выше, чем в дистрибуции.',
                            'Затраты на заключение одной сделки в любом канале прямых продаж существенно более дорогие с т.з. экономики. А это еще один плюс в пользу внешней дистрибьюции.',
                            'Обеспечение большего объема продаж, чем при прямом маркетинге производителя. Наша стратегия – это работа на эксклюзивных условиях в регионе.',
                            'Для производителя работа с эксклюзивным дистрибьютором позволяет выйти на долгосрочную эффективность продаж.',
                        ],
                        check_anounce: [
                            'Мы открыты к тесному взаимодействию и сотрудничеству с нашими партнерами.',
                            'Работа с нами на основе дистрибуции – это партнёрство на долго.'
                        ],
                    }
                ]
            },
            // История компании
            historySection: {
                pageTitle: {
                    black: 'ИСТОРИЯ',
                    color: ' КОМПАНИИ',
                },
                history_items: [
                    {
                        year: 2009,
                        title: 'Год основания группы компании orange group – 2009'
                    },
                    {
                        year: 2009,
                        title: 'Дистрибьюция семечек Джинн на территории г. Алматы и Алматинской области.'
                    },
                    {
                        year: 2013,
                        title: 'Начато производство очищенных семечек Зёрнышко.'
                    },
                    {
                        year: 2014,
                        title: 'Открыто представительство в г. Тараз.'
                    },
                    {
                        year: 2015,
                        title: 'Открыто представительство в г. Караганда.'
                    },
                    {
                        year: 2015,
                        title: 'Открытие первого представительства на территории Российской Федерации, в г. Астрахань.'
                    },
                    {
                        year: 2015,
                        title: 'Начато производство кукурузных палочек.'
                    },
                    {
                        year: 2015,
                        title: 'Открыто представительство на Северном Кавказе, в Чеченской Республике, в Республике Дагестан, в Республике Осетия, в Республике Ингушетия.'
                    },
                    {
                        year: 2018,
                        title: 'Открыто производство мясных полуфабрикатов с брендом «Nord Standart».'
                    },
                    {
                        year: 2021,
                        title: 'Дистрибьюция новых торговых марок - «Всё в дом», крупы «Salus», бытовая химия белоруской фабрики «Сонца».'
                    },
                    {
                        year: 2022,
                        title: 'Открыто представительство в республике Азербайджане.'
                    },
                    {
                        year: 2022,
                        title: 'Производство собственных новых торговых марок - Макароны «Grazio», лапша быстрого приготовления «YouSheFu», бытовая химия «Уютный дом», кукурузные палочки «Papago», «Ями-Ями» и кукурузные снеки «Twister».'
                    },
                ]
            },
            // Контакты раздел
            contactsSection: {
                pageTitle: {
                    black: 'БУДЕМ РАДЫ',
                    color: ' СОТРУДНЕЧЕСТВУ',
                },
                contacts_info: {
                    adres: 'Республика Казахстан, <br> г. Алматы, Илийский тракт, 11',
                    phones: [
                        '+7 (727) 290-23-95',
                        '+7 (727) 232-85-56'
                    ],
                    emails: [
                        'info@orange-group.kz',
                        '1@orange-group.kz'
                    ]
                },
            }
        },
        en_lang: {
            // Основные настройки
            developer: 'Design and development - ',
            copy: '© 2025, Orange Group. All rights reserved.',
            header: {
                textMsg: 'Hello, I am writing to you from the site https://orange-group.kz',
                phoneMsg: '+77777086617',
                wapp_text: 'Write to us on Whatsapp',
            },
            nav: [
                {
                    name: 'Our companies',
                    href:'#about',
                },
                {
                    name: 'Distribution',
                    href:'#distribution',
                },
                {
                    name: 'Business strategy',
                    href:'#strategy',
                },
                {
                    name: 'Company history',
                    href:'#history',
                },
                {
                    name: 'Cooperation',
                    href:'#cooperation',
                },
                {
                    name: 'Contacts',
                    href:'#contacts',
                },
            ],
            formSetup: {
                name: 'Name',
                company: 'Company',
                phone: '+7 (',
                email: 'E-mail',
                message: 'Your Message',
                btn: 'Send to',
                anounce: 'All fields are mandatory',
                sucsess_msg: 'Thank you your message has been sent',
                admin_email: 'smart.ind@bk.ru',
                form_title: 'Do you have any questions? We will be happy to answer them.',
            },
            // Главный блок
            indexPromo: {
                promo: [
                    {
                        title: 'Direct distribution and production',
                        img: require('@/assets/images/samples/slide-pic-01.png'),
                    },
                    {
                        title: 'Own logistics center and trucks',
                        img: require('@/assets/images/samples/slide-pic-01.png'),
                    },
                    {
                        title: 'Uninterrupted supply',
                        img: require('@/assets/images/samples/slide-pic-01.png'),
                    },
                    {
                        title: 'Profitable cooperation for the development of your business',
                        img: require('@/assets/images/samples/slide-pic-01.png'),
                    },
                ],
                promo_desc: {
                    number_desc: 'Shipments of goods made by us for all the time',
                    full_desc: 'Today, our line of business is direct distribution and production, in conjunction with the logistics of food products at retail through our own trade channels.'
                }
            },
            // Раздел Наши компании
            aboutSection: {
                btn_more: 'Further details',
                pageTitle: {
                    black: 'Our',
                    color: 'Companies',
                },
                leftDesc: [
                    'For more than 15 years, Global Foods has been the official and only distributor of popular sunflower seeds "Dzhinn" and premium peanut snack "Karavan Orekhov" of the Russian company "Smart" in the Republic of Kazakhstan.'
                ],
                rightDesc: [
                    'TUKANO produces YuSheFu instant noodles, Papago, Yami-Yami and Twister snacks.',
                    'He is a distributor of carbonated drinks of TM "Chupa Chups", flour of TM "Belyaevskaya".'
                ],
                aboutCards: [
                    {
                        id: 1,
                        modal: true,
                        card_img: require('@/assets/images/samples/about-cards/about-card-01.png'),
                        title: 'The company "Global Foods" specializes in the distribution of sunflower seeds "Dzhinn" and peanut "Karavan Orekhov".',
                        desc: [
                            'The distribution products are represented by two brands of the Russian company Smart - sunflower seeds "Dzhinn" and peanut "Karavan Orekhov".',
                            'Sunflower seeds "Dzhinn" are a long-loved, fragrant and bright original taste of seeds. "Karavan Orekhov" are selected peanut kernels with salt, in which a moderate amount of salt and oil turns the product into a full-fledged snack or snack for drinks.'
                        ],
                        images: [
                            require('@/assets/images/samples/about-cards/about-card-01-01.png'),
                            require('@/assets/images/samples/about-cards/about-card-01-02.png'),
                            require('@/assets/images/samples/about-cards/about-card-01-03.png'),
                            require('@/assets/images/samples/about-cards/about-card-01-04.png')
                        ]
                    },
                    {
                        id: 2,
                        modal: true,
                        card_img: require('@/assets/images/samples/about-cards/about-card-02.png'),
                        title: 'The company "Global Foods" specializes in the distribution of sunflower seeds "Dzhinn" and peanut "Karavan Orekhov".',
                        desc: [
                            'The distribution products are represented by two brands of the Russian company Smart - sunflower seeds "Dzhinn" and peanut "Karavan Orekhov".',
                            'Sunflower seeds "Dzhinn" are a long-loved, fragrant and bright original taste of seeds. "Karavan Orekhov" are selected peanut kernels with salt, in which a moderate amount of salt and oil turns the product into a full-fledged snack or snack for drinks.'
                        ],
                        images: [
                            require('@/assets/images/samples/about-cards/about-card-01-01.png'),
                            require('@/assets/images/samples/about-cards/about-card-01-02.png'),
                            require('@/assets/images/samples/about-cards/about-card-01-03.png'),
                            require('@/assets/images/samples/about-cards/about-card-01-04.png')
                        ]
                    },
                    {
                        id: 3,
                        modal: true,
                        card_img: require('@/assets/images/samples/about-cards/about-card-03_2.png'),
                        title: 'Chupa Chups carbonated soft drink with flavors of your favorite lollipops Chupa Chups.',
                        desc: [
                            'It is wonderfully refreshing and fruity-sour, as we used to see in lollipops. Enjoy the great taste of your favorite soda lollipop!'
                        ],
                        images: [
                            require('@/assets/images/samples/about-cards/products/Chupa_1.png'),
                            require('@/assets/images/samples/about-cards/products/Chupa_2.png'),
                            require('@/assets/images/samples/about-cards/products/Chupa_3.png'),
                            require('@/assets/images/samples/about-cards/products/Chupa_4.png'),
                            require('@/assets/images/samples/about-cards/products/Chupa_5.png'),
                        ]
                    },
                    {
                        id: 4,
                        modal: true,
                        card_img: require('@/assets/images/samples/about-cards/about-card-04_2.png'),
                        title: 'Belyaevskaya flour has been produced by a modern flour milling company using high-tech equipment for more than 10 years.',
                        desc: [
                            'Full guarantee of compliance of the supplied products with safety and quality standards!'
                        ],
                        images: [
                            require('@/assets/images/samples/about-cards/products/Muka_01.png'),
                            require('@/assets/images/samples/about-cards/products/Muka_02.png'),
                            require('@/assets/images/samples/about-cards/products/Muka_03.png'),
                        ]
                    },
                    // {
                    //     id: 3,
                    //     modal: true,
                    //     card_img: require('@/assets/images/samples/about-cards/about-card-03.png'),
                    //     title: 'The company "Tukano" is engaged in the sale of its own brand of household chemicals "Uyutny Dom".',
                    //     desc: [
                    //         'Household chemicals of "Uyutny Dom" trademark are high-quality home cleaning products, at a great price and with the widest assortment.',
                    //         'Our household chemicals create cleanliness and freshness in your house every day.'
                    //     ],
                    //     images: [
                    //         require('@/assets/images/samples/about-cards/products/product_07_01.png'),
                    //         require('@/assets/images/samples/about-cards/products/product_07_02.png'),
                    //         require('@/assets/images/samples/about-cards/products/product_07_03.png'),
                    //         require('@/assets/images/samples/about-cards/products/product_07_04.png'),
                    //         require('@/assets/images/samples/about-cards/products/product_07_05.png'),
                    //     ]
                    // },
                    // {
                    //     id: 4,
                    //     modal: true,
                    //     card_img: require('@/assets/images/samples/about-cards/about-card-04.png'),
                    //     title: 'The company "Tukano" is engaged in the distribution of food and household chemicals of the Kazakh manufacturer "Vse v dom".',
                    //     desc: [
                    //         'Household chemicals, paper products, canned goods, cereals, seasonings and much, much more. The best value for money - take "Vse v dom".',
                    //         'The brand belongs to a large Kazakh retailer "Magnum". Magnum produces its own brand products at contract manufacturing facilities, most of which are located in Kazakhstan. But, for example, champignons are canned in China, and straw potatoes are produced in Kyrgyzstan.'
                    //     ],
                    //     images: [
                    //         require('@/assets/images/samples/about-cards/products/product_03_01.png'),
                    //         require('@/assets/images/samples/about-cards/products/product_03_02.png'),
                    //         require('@/assets/images/samples/about-cards/products/product_03_03.png'),
                    //         require('@/assets/images/samples/about-cards/products/product_03_04.png'),
                    //         require('@/assets/images/samples/about-cards/products/product_03_05.png'),
                    //         require('@/assets/images/samples/about-cards/products/product_03_06.png'),
                    //         require('@/assets/images/samples/about-cards/products/product_03_07.png'),
                    //         require('@/assets/images/samples/about-cards/products/product_03_08.png'),
                    //         require('@/assets/images/samples/about-cards/products/product_03_09.png'),
                    //         require('@/assets/images/samples/about-cards/products/product_03_10.png'),
                    //         require('@/assets/images/samples/about-cards/products/product_03_11.png'),
                    //         require('@/assets/images/samples/about-cards/products/product_03_12.png'),
                    //     ]
                    // },
                    // {
                    //     id: 5,
                    //     modal: true,
                    //     card_img: require('@/assets/images/samples/about-cards/about-card-05.png'),
                    //     title: 'The company "Tukano" is engaged in the sale of its own brand of pasta "Grazio".',
                    //     desc: [
                    //         'Pasta of "Grazio" brand is a natural product made from durum wheat. Thanks to this, when cooking pasta perfectly retains its shape and taste properties. A wide range of products allows our consumers to delight themselves and their families with delicious, healthy and varied dishes every day. Thanks to the special grinding, all the dietary properties of durum wheat are preserved in "Grazio" pasta.'
                    //     ],
                    //     images: [
                    //         require('@/assets/images/samples/about-cards/products/product_05_01.png'),
                    //         require('@/assets/images/samples/about-cards/products/product_05_02.png'),
                    //         require('@/assets/images/samples/about-cards/products/product_05_03.png'),
                    //     ]
                    // },
                    // {
                    //     id: 6,
                    //     modal: true,
                    //     card_img: require('@/assets/images/samples/about-cards/about-card-06.png'),
                    //     title: 'The company "Tukano" distributes household chemicals of the Belarusian factory "Sontsa".',
                    //     desc: [
                    //         'The perfume and cosmetics company "Sontsa" is a manufacturer of modern high–quality laundry products, household chemicals and personal hygiene products. The factory is located in Belarus. More than 200 people work for the company and the factory.',
                    //         'An innovative approach to technology and product development makes the distribution of these products in demand.'
                    //     ],
                    //     images: [
                    //         require('@/assets/images/samples/about-cards/products/product_04_01.png'),
                    //         require('@/assets/images/samples/about-cards/products/product_04_02.png'),
                    //         require('@/assets/images/samples/about-cards/products/product_04_03.png'),
                    //         require('@/assets/images/samples/about-cards/products/product_04_04.png'),
                    //         require('@/assets/images/samples/about-cards/products/product_04_05.png'),
                    //     ]
                    // },
                    // {
                    //     id: 7,
                    //     modal: true,
                    //     card_img: require('@/assets/images/samples/about-cards/about-card-07.png'),
                    //     title: 'The company "Tukano" is engaged in the distribution of cereals and flakes of the Kazakh manufacturer "Salus".',
                    //     desc: [
                    //         'Porridge is the basis of our diet. The importance of cereal dishes is difficult to overestimate, since ancient times porridge was not just food, but had a special meaning, uniting all layers of society. Cereals are a storehouse of useful substances: vitamins, trace elements, proteins and fiber. Therefore, our company is actively developing distribution in this promising and useful direction, offering a wide range of cereals and flakes of "Salus" brand.',
                    //     ],
                    //     images: [
                    //         require('@/assets/images/samples/about-cards/products/product_02_01.png'),
                    //         require('@/assets/images/samples/about-cards/products/product_02_02.png'),
                    //         require('@/assets/images/samples/about-cards/products/product_02_03.png'),
                    //         require('@/assets/images/samples/about-cards/products/product_02_04.png'),
                    //         require('@/assets/images/samples/about-cards/products/product_02_05.png'),
                    //         require('@/assets/images/samples/about-cards/products/product_02_06.png'),
                    //         require('@/assets/images/samples/about-cards/products/product_02_07.png'),
                    //         require('@/assets/images/samples/about-cards/products/product_02_08.png'),
                    //     ]
                    // },
                    {
                        id: 8,
                        modal: true,
                        card_img: require('@/assets/images/samples/about-cards/about-card-08.png'),
                        title: 'The company "Tukano" is engaged in the sale of its own brand of instant noodles "YouSheFu".',
                        desc: [
                            'Instant noodles of "YouSheFu" brand are, first of all, nutritious noodles with a rich Asian taste, which will perfectly satisfy hunger.',
                            'Our instant noodles are perfect for a full lunch or dinner at work, at home or on a hike with friends.'
                        ],
                        images: [
                            require('@/assets/images/samples/about-cards/products/YUSHEFU_01.png'),
                            require('@/assets/images/samples/about-cards/products/YUSHEFU_02.png'),
                            require('@/assets/images/samples/about-cards/products/YUSHEFU_03.png'),
                            require('@/assets/images/samples/about-cards/products/YUSHEFU_04.png'),
                            require('@/assets/images/samples/about-cards/products/YUSHEFU_05.png'),
                            require('@/assets/images/samples/about-cards/products/YUSHEFU_06.png'),
                        ]
                    },
                    {
                        id: 9,
                        modal: true,
                        card_img: require('@/assets/images/samples/about-cards/about-card-09.png'),
                        title: 'The company "Tukano" is engaged in the production and sale of its own trademark corn curls "Papago" and "Yami-Yami" and corn snacks "Twister".',
                        desc: [
                            'Sweet and airy, crispy corn curls are made of high-quality corn in a delicate powdered sugar. The product does not contain preservatives and genetically modified components. Corn snacks "Twister" are tiny snacks based on corn. Corn curls and snacks are a favorite treat of children and adults, and a pleasant price makes them affordable.'
                        ],
                        images: [
                            require('@/assets/images/samples/about-cards/products/product_08_01.png'),
                            require('@/assets/images/samples/about-cards/products/product_08_02.png'),
                            require('@/assets/images/samples/about-cards/products/product_08_03.png'),
                            require('@/assets/images/samples/about-cards/products/product_08_04.png'),
                            require('@/assets/images/samples/about-cards/products/product_08_05.png'),
                            require('@/assets/images/samples/about-cards/products/product_08_06.png'),
                        ]
                    },
                    {
                        id: 10,
                        modal: true,
                        card_img: require('@/assets/images/samples/about-cards/about-card-10.png'),
                        title: 'The company "Tukano" is engaged in the production and sale of its own trademark corn curls "Papago" and "Yami-Yami" and corn snacks "Twister".',
                        desc: [
                            'Sweet and airy, crispy corn curls are made of high-quality corn in a delicate powdered sugar. The product does not contain preservatives and genetically modified components. Corn snacks "Twister" are tiny snacks based on corn. Corn curls and snacks are a favorite treat of children and adults, and a pleasant price makes them affordable.'
                        ],
                        images: [
                            require('@/assets/images/samples/about-cards/products/product_08_01.png'),
                            require('@/assets/images/samples/about-cards/products/product_08_02.png'),
                            require('@/assets/images/samples/about-cards/products/product_08_03.png'),
                            require('@/assets/images/samples/about-cards/products/product_08_04.png'),
                            require('@/assets/images/samples/about-cards/products/product_08_05.png'),
                            require('@/assets/images/samples/about-cards/products/product_08_06.png'),
                        ]
                    },
                    {
                        id: 11,
                        modal: true,
                        card_img: require('@/assets/images/samples/about-cards/about-card-11.png'),
                        title: 'The company "Tukano" is engaged in the production and sale of its own trademark corn curls "Papago" and "Yami-Yami" and corn snacks "Twister".',
                        desc: [
                            'Sweet and airy, crispy corn curls are made of high-quality corn in a delicate powdered sugar. The product does not contain preservatives and genetically modified components. Corn snacks "Twister" are tiny snacks based on corn. Corn curls and snacks are a favorite treat of children and adults, and a pleasant price makes them affordable.'
                        ],
                        images: [
                            require('@/assets/images/samples/about-cards/products/product_08_01.png'),
                            require('@/assets/images/samples/about-cards/products/product_08_02.png'),
                            require('@/assets/images/samples/about-cards/products/product_08_03.png'),
                            require('@/assets/images/samples/about-cards/products/product_08_04.png'),
                            require('@/assets/images/samples/about-cards/products/product_08_05.png'),
                            require('@/assets/images/samples/about-cards/products/product_08_06.png'),
                        ]
                    },
                ]
            },
            // Раздел Дистрибьюция
            distributionSection: {
                pageTitle: {
                    black: 'Our',
                    color: ' Distribution',
                },
                desc: 'Currently the group of companies "Orange Group" has a large partner network in the Republic of Kazakhstan and abroad.',
                desc_number: "Regular customers in the company's database for today.",
                desc_table_up: 'The group of companies "Orange Group" conducts its business activities and has full-fledged branches on the territory of the states, republics and cities presented in the table.',
                desc_table_down: 'The main partners representing the own and distribution products of the group of companies "Orange Group" on the territory of the Republic of Kazakhstan:',
                cards_desc_left: 'The company has modern automated sales systems and conducts online movement of sales representatives and its online sales. Every day, the database receives data on the merchandising of goods in retail outlets, the layout and control of the recommended order.',
                cards_desc_right: "The sales team forms the customer's order, actively offers the product, reporting on new products, promotional offers for products. Sales representatives visit each outlet 1-2 times a week, tracking the remains in the buyer's warehouse.",
            },
            // Раздел Стратегия
            strategySection: {
                pageTitle: {
                    black: 'Business',
                    color: ' Strategy',
                },
                desc: 'The company leases a big modern logistics center. This center own fleet of trucks for timely and high-quality deliveries.',
                desc_number: 'Qualified specialists work for the benefit of the company',
                desc_left: [
                    'The transition to distribution (the official representation of manufacturers) and the production of own products allow being engaged in high-quality distribution of products in the regions.',
                    'In turn, partnership relations based on the exclusive right to distribute products allow the distributor to reduce price fluctuations in the market, and the manufacturer to represent the interests of its own trademarks and brands.'
                ],
                desc_right: [
                    '"Orange Group" uses Class A warehouses that meet international standards. The companys sales teams are equipped with modern PDAs that allow sending applications online.',
                    '"Orange Group" values the reputation of an honest partner very much, so we give priority to decency, strict compliance with pricing policy, compliance with the terms of the contract while working with any supplier and buyer.'
                ]
            },
            // Раздел сотрудничество
            cooperationSection: {
                year: 'Years',
                pageTitle: {
                    black: 'COOPERATION',
                    color: ' AND EFFICIENCY',
                },
                desc: 'Today "Orange Group" is a rapidly developing group of companies that has already formed its clear position in the market',
                desc_number: 'We have been successfully conducting our business in the market of Kazakhstan and the CIS',
                desc_cooperation_top: 'The company will become successful only when it manages to make its partners successful, therefore, in a partner bundle:',
                desc_cooperation_bottom: 'the task of the distributor, that is, our company, is to make sure that all products are in demand by the consumer.',
                cooperation: [
                    'MANUFACTURER',
                    'DISTRIBUTOR',
                    'STORE',
                    'BUYER'
                ],
                cooperation_title_left: '"Orange Group" has developed the basic principles of effective distribution, which include the following:',
                cooperation_title_right: 'In turn, the manufacturer, on the rights of exclusive distribution, receives the following benefits from partnership with the group of companies "Orange Group":',
                icons_list: [
                    {
                        icon: require('@/assets/images/samples/cooperation/cooperation_icon_01.svg'),
                        title: "Promotion of the manufacturer's products in each segment",
                    },
                    {
                        icon: require('@/assets/images/samples/cooperation/cooperation_icon_02.svg'),
                        title: 'A team of the best sales representatives',
                    },
                    {
                        icon: require('@/assets/images/samples/cooperation/cooperation_icon_03.svg'),
                        title: 'Effective coverage of the city, region and important sales channels',
                    },
                    {
                        icon: require('@/assets/images/samples/cooperation/cooperation_icon_04.svg'),
                        title: 'Direct distribution and appropriate sales channels',
                    },
                ],
                cooperation_list: [
                    'Considerable saving in resources and time on creating  own representative office in the region;',
                    'Logistics services that include storage and delivery of goods to customers within 24 hours after the order;',
                    'The highest quality approach to each goods  item of the Brand;',
                    'Setting goals for sales volumes and their fulfillment, as  well as analyzing market conditions;',
                    '100% coverage of a specific segment for a specific goods item;',
                    'An effective information system for managing the  delivery process.'
                ],
                cooperation_orange: [
                    {
                        title: 'Manufacturer',
                        img: require('@/assets/images/samples/cooperation/orange_icon_01.svg'),
                    },
                    {
                        title: 'Distributor',
                        img: require('@/assets/images/samples/cooperation/orange_icon_02.svg'),
                    },
                    {
                        title: 'Retail stores',
                        img: require('@/assets/images/samples/cooperation/orange_icon_03.svg'),
                    },
                    {
                        title: 'Buyer',
                        img: require('@/assets/images/samples/cooperation/orange_icon_04.svg'),
                    },
                ],
                cooperation_collapse: [
                    {
                        title: 'The advantage of partner programs and their mutual benefit:',
                        check_items: [
                            'We would like to focus on the main advantages when working with our company as a distributor in Almaty, Taldy-Korgan and Zhambyl region.',
                            'We are well-versed in the situation on the Kazakh market, which means that we can keep the manufacturer constantly informed: about competitors, about the state of the market, about buyers, etc.',
                            'The exclusion of wholesale intermediaries in the distribution channel allows us to obtain the highest quality information on sales at retail outlets and provide this information to the manufacturer.',
                            'Most of our customers prefer to work with us because of the wide range of products that we provide at the current time (in addition to distribution items, there are also wholesale ones available).',
                            'We know the advertising market of the region very well and know the most effective ways of influence of advertising on the consumer.',
                            'An effective system of incentives for the sales team makes it possible to carry out the most assertive offer for each distribution item.',
                        ],
                        check_anounce: false,
                    },
                    {
                        title: 'In addition, when working through an external distributor, the Supplier receives a great financial and economic advantage:',
                        check_items: [
                            'The release of financial resources that could be spent to build its own representative office.',
                            'The opportunity to get a large share of profit, the standard of which in production, as a rule, is 2 times higher than in distribution.',
                            'The costs of concluding one transaction in any direct sales channel are significantly more expensive in terms of economics. And this is another plus in favor of external distribution.',
                            'Provision of more sales than with direct marketing of the manufacturer. Our strategy is to work on exclusive terms in the region.',
                            'For the manufacturer, working with an exclusive distributor allows it to achieve long-term sales efficiency.',
                        ],
                        check_anounce: [
                            'We are open to close collaboration and cooperation with our partners.',
                            'Working with us on the basis of distribution is a partnership for a long time.'
                        ],
                    }
                ]
            },
            // История компании
            historySection: {
                pageTitle: {
                    black: 'Company',
                    color: ' History',
                },
                history_items: [
                    {
                        year: 2009,
                        title: 'The year of foundation of the group of companies "Orange Group" is 2009.'
                    },
                    {
                        year: 2009,
                        title: 'Distribution of sunflower seeds "Dzhinn" on the territory of Almaty and Almaty region.'
                    },
                    {
                        year: 2013,
                        title: 'The production of peeled sunflower seeds "Zernyshko" has begun.'
                    },
                    {
                        year: 2014,
                        title: 'A representative office has been opened in Taraz.'
                    },
                    {
                        year: 2015,
                        title: 'A representative office has been opened in Karaganda.'
                    },
                    {
                        year: 2015,
                        title: 'Opening of the first representative office on the territory of the Russian Federation, in Astrakhan.'
                    },
                    {
                        year: 2015,
                        title: 'The production of corn curls has begun.'
                    },
                    {
                        year: 2015,
                        title: 'A representative office has been opened in the North Caucasus, in the Chechen Republic, in the Republic of Dagestan, in the Republic of Ossetia, in the Republic of Ingushetia.'
                    },
                    {
                        year: 2018,
                        title: 'The production of semi-finished meat products  with "Nord Standart" brand has been opened.'
                    },
                    {
                        year: 2021,
                        title: 'Distribution of new trademarks - "Vse v dom", cereals "Salus", household chemicals of the Belarusian factory "Sontsa".'
                    },
                    {
                        year: 2022,
                        title: 'A representative office has been opened in the Republic of Azerbaijan.'
                    },
                    {
                        year: 2022,
                        title: 'Production of own new brands - Pasta "Grazio", instant noodles "YouSheFu", household chemicals "Uyutny Dom", corn curls "Papago", "Yami-Yami" and corn snacks "Twister".'
                    },
                ]
            },
            // Контакты раздел
            contactsSection: {
                pageTitle: {
                    black: 'WE WILL BE GLAD',
                    color: ' TO COOPERATE',
                },
                contacts_info: {
                    adres: 'Republic of Kazakhstan, <br> Almaty, Iiisky tract, 11',
                    phones: [
                        '+7 (727) 290-23-95',
                        '+7 (727) 232-85-56'
                    ],
                    emails: [
                        'info@orange-group.kz',
                        '1@orange-group.kz'
                    ]
                },
            }
        }
    },
    getters: {
        loadOpenFormModal: state => state.isOpenFormModal,
        loadOpenModalPromo: state => state.isOpenModalPromo,
        loadOpenSendwich: state => state.isOpenSendwich,
        //Get global lang Content Var
        loadMainLang: state => state.mainLang,
    },
    actions: {
        installMainLang_Global({commit}, payload) {
            commit('setUp_mainLang', payload);
        },
    },
    mutations: {
        Open_form_modal(state, data) {
            state.isOpenFormModal = data
        },
        Open_promo_modal(state, data) {
            state.isOpenModalPromo = data
        },
        Open_Sendwich(state, data) {
            state.isOpenSendwich = data
        },
        //
        setUp_mainLang(state, lang) {
            if (lang == 'ru') {
                state.mainLang = state.ru_lang
            } else if (lang == 'en') {
                state.mainLang = state.en_lang
            } else {
                state.mainLang = state.ru_lang
            }
        },
    },
})
